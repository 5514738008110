import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';

export const useDashboardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '5px',
    },
    displayRoot: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    progress: {
      marginLeft: '50%',
      marginTop: '30%',
      color: theme.palette.primary.light.toString(),
    },
    papper: {
      margin: '5px',
      minWidth: 275,
      width: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }),
);
