import { RequestType } from '../../../Service/RequestType';
import { callService } from '../../../Service/ServiceBase';
import {
  CustomerModuleMetaData,
  Customermodulevariable,
  ModuleVariable,
} from './Model/ModulesModel';

class ModuleService {
  GetCustomerModules(customerId: string): Promise<CustomerModuleMetaData> {
    const customerModuleMetaData = callService(
      'modules',
      `${customerId}`,
      RequestType.GET,
    );
    return customerModuleMetaData;
  }

  GetModuleVariables(
    module: string,
    customerId: string,
  ): Promise<ModuleVariable[]> {
    const modulevariables = callService(
      'modules',
      `ModuleVariables/${module}/${customerId}`,
      RequestType.GET,
    );
    return modulevariables;
  }

  async SaveModulesForCustomer(
    customerId: string,
    selectedModules: Customermodulevariable[],
  ): Promise<void> {
    const modulevariable = await callService(
      'modules',
      `customermodulevariables/${customerId}/save`,
      RequestType.PUT,
      selectedModules,
    );
    return modulevariable;
  }
}
export const moduleService: ModuleService = new ModuleService();
