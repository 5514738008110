import React from 'react';
import TenantHealthPage from '../Components/TenantHealthPage';
import { Paper } from '@mui/material';
import GlobalHealth from '../Components/GlobalHealth';
import TenantDashboardHome from '../../TenantDashboard/TenantDashboardHome';

interface Props {
  Product: string;
  setDropDown: (flag: boolean) => void;
}

const DashboardHome = (props: Props) => {
  return (
    <Paper elevation={0} sx={{ margin: 2 }}>
      <TenantDashboardHome
        Product={props.Product}
        setDropDown={props.setDropDown}
      />
    </Paper>
  );
};
export default DashboardHome;
