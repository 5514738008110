import React from 'react';
import {
  Skeleton as MuiSkeleton,
  Theme,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
  }),
);

const Skeleton = () => {
  const classes = useStyles();

  return (
    <Paper>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableBody>
            {Array.from({ length: 10 }).map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <MuiSkeleton />
                </TableCell>
                <TableCell>
                  <MuiSkeleton />
                </TableCell>
                <TableCell>
                  <MuiSkeleton />
                </TableCell>
                <TableCell>
                  <MuiSkeleton />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default Skeleton;
