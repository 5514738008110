import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import { AzureDateRange, TenantCost, TenantSLA } from './Model/CostModel';

class DashboardService {
  getAzureTenantWiseCost(azurecost: AzureDateRange): Promise<TenantCost[]> {
    const TenantsCost = callService(
      'azurecost',
      `customers`,
      RequestType.POST,
      azurecost,
    );
    return TenantsCost;
  }
  getAzureTenantWiseSLA(azurecost: AzureDateRange): Promise<TenantSLA[]> {
    const TenantsCost = callService(
      'azuremonitoring',
      `tenants/sla`,
      RequestType.POST,
      azurecost,
    );
    return TenantsCost;
  }
}

export const dashboardService: DashboardService = new DashboardService();
