import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { TableHeader } from '../../../Common/CommonModel';
import { TenantCostAndSLA } from '../Model/CostModel';
import { EnhancedTableProps } from '../Model/TenantCostProps';
import InfoIcon from '@mui/icons-material/Info';

const tenantCostheadRows: TableHeader<TenantCostAndSLA>[] = [
  {
    id: 'TenantName',
    numeric: false,
    disablePadding: false,
    label: 'Tenant',
    enableSorting: true,
  },
  {
    id: 'Cost',
    numeric: false,
    disablePadding: false,
    label: 'Cost (kr)',
    enableSorting: true,
  },
  {
    id: 'CostUsd',
    numeric: false,
    disablePadding: false,
    label: 'Cost ($)',
    enableSorting: true,
  },
  {
    id: 'Sla',
    numeric: false,
    disablePadding: false,
    label: 'SLA (%)',
    enableSorting: true,
    tooltip: true,
    helperText: 'Excluded maintainance window for calculating sla percentage',
  },
];

export default function CostTableHead(props: Readonly<EnhancedTableProps>) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof TenantCostAndSLA) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {tenantCostheadRows.map((tenantcostrow) => (
          <TableCell
            key={tenantcostrow.id}
            align={tenantcostrow.numeric ? 'right' : 'left'}
            padding={tenantcostrow.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === tenantcostrow.id ? order : false}
          >
            {tenantcostrow.enableSorting ? (
              <TableSortLabel
                active={orderBy === tenantcostrow.id}
                direction={order}
                onClick={createSortHandler(tenantcostrow.id)}
              >
                {tenantcostrow.label}
                {tenantcostrow.tooltip && (
                  <Tooltip title={tenantcostrow.helperText}>
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                )}
              </TableSortLabel>
            ) : (
              <div>
                {tenantcostrow.label}
                {tenantcostrow.tooltip && (
                  <Tooltip title={tenantcostrow.helperText}>
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                )}
              </div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
