import React, { useEffect, useState } from 'react';

import { metricsService } from '../MetricsService';
import { TenantMetricsData, TenantMetricsProps } from '../Model/MetricsModel';
import {
  CircularProgress,
  Card,
  CardContent,
  Typography,
  Divider,
  Tooltip,
  Fab,
  Icon,
} from '@mui/material';
import { Dictionary } from '../../../Common/CommonModel';
import { convertValueToString } from '../Helpers/MetricsHelpers';
import { capitalize, getLocalDate } from '../../../../Helpers/UtilityMethods';
import { useFabStyles } from '../../../../Common/Styles/FabStyle';
import OverallUserMetricsChart from './Charts/OverallUserMetricsChart';
import { toast } from 'react-toastify';
import { callService } from '../../../../Service/ServiceBase';
import { RequestType } from '../../../../Service/RequestType';
import { useDashboardStyles } from '../../../../Common/Styles/DashboardStyle';

enum MetricType {
  Storage = 'Storage',
  User = 'User',
  Time = 'Time',
}

const generateTypeColor = (type: MetricType) => {
  switch (type) {
    case MetricType.Storage:
      return '#6d4d7e';
    case MetricType.User:
      return '#342c39';
    case MetricType.Time:
      return '#575757';
  }
};

export default function TenantMetrics(props: TenantMetricsProps) {
  const classes = useDashboardStyles();
  const fabStyles = useFabStyles();

  const { farmId, tenantId } = props;

  const [latestTenantMetrics, setlatestTenantMetrics] = useState<
    TenantMetricsData[]
  >([]);
  const [customerMetricsNameMap, setCustomerMetricsNameMap] = useState<
    Dictionary<TenantMetricsData>
  >({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadMetrics();
  }, [tenantId]);

  const loadMetrics = async () => {
    setLoading(true);
    const metrics = await metricsService.getLatestTenantMetrics(tenantId);
    const metricsNameMapping: Dictionary<TenantMetricsData> = {};
    metrics.forEach((metric) => {
      metricsNameMapping[metric.Name] = metric;
    });
    setlatestTenantMetrics(metrics);
    setCustomerMetricsNameMap(metricsNameMapping);
    setLoading(false);
  };

  const refreshTenantMetrics = async () => {
    setLoading(true);
    callService('metrics', `tenant/${tenantId}`, RequestType.POST).then(() => {
      toast.success('metrics updated successfully!');
      loadMetrics();
    });
  };

  const getSelectedMetricsData = (metricName: string) => {
    return customerMetricsNameMap[metricName];
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <div>
          <div className={classes.displayRoot}>
            <Tooltip title={'refresh metrics'}>
              <Fab
                className={fabStyles.firstFab}
                color="primary"
                onClick={refreshTenantMetrics}
              >
                <Icon>sync</Icon>
              </Fab>
            </Tooltip>
            <Card
              className={classes.papper}
              sx={{
                backgroundColor: generateTypeColor(MetricType.Time),
              }}
            >
              <CardContent>
                <Typography className={classes.pos} color="#fff" variant="h6">
                  Updated Time
                </Typography>

                <Typography variant="h4" component="h2" color="#fff">
                  {latestTenantMetrics[0] &&
                    getLocalDate(latestTenantMetrics[0].TimeStamp)}
                </Typography>
              </CardContent>
            </Card>
            {latestTenantMetrics.map((metrics) => (
              <Card
                className={classes.papper}
                key={metrics.Id}
                sx={{
                  backgroundColor: generateTypeColor(
                    capitalize(metrics.Type) as MetricType,
                  ),
                }}
              >
                <CardContent>
                  <Typography className={classes.pos} color="#fff" variant="h6">
                    {capitalize(getSelectedMetricsData(metrics.Name).Type)}
                  </Typography>
                  <Typography
                    className={classes.title}
                    color="#fff"
                    gutterBottom
                  >
                    {getSelectedMetricsData(metrics.Name).DisplayName}:
                  </Typography>
                  <Typography variant="h4" component="h2" color="#fff">
                    {convertValueToString(getSelectedMetricsData(metrics.Name))}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
          <Divider />
          <OverallUserMetricsChart
            farmId={farmId}
            tenantId={tenantId}
          ></OverallUserMetricsChart>
        </div>
      )}
    </div>
  );
}
