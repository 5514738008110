import React, { useEffect, useState } from 'react';
import { AzureDateRange, TenantCostAndSLA } from '../Model/CostModel';
import { dashboardService } from '../DashboardService';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import Skeleton from './Skeleton';
import { getSorting, stableSort } from '../../../../Helpers/UtilityMethods';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Order } from '../../../../Helpers/Literals';
import CostTableHead from './CostTableHeader';
import CostHeaderToolbar from './CostHeaderToolbar';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    subDomainWidth: {
      width: 421,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

const CostPanel = () => {
  const classes = useStyles();
  const rowsPerPage = 10;
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] =
    React.useState<keyof TenantCostAndSLA>('TenantName');
  const [selectedDateRange, setSelectedDateRange] = useState<AzureDateRange>({
    From: dayjs().subtract(30, 'day').toString(),
    To: dayjs().toString(),
  });

  useEffect(() => {
    loadTenantsData();
  }, []);

  const getFormatedDateTime = (value: Dayjs) => {
    return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
  };

  const handleFromDateChange = (newValue: Dayjs | null) => {
    console.log(newValue);
    if (newValue != null) {
      setSelectedDateRange({
        ...selectedDateRange,
        From: getFormatedDateTime(newValue),
      });
    }
  };

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) {
    setPage(newPage);
  }

  const handleToDateChange = (newValue: Dayjs | null) => {
    if (newValue != null) {
      setSelectedDateRange({
        ...selectedDateRange,
        To: getFormatedDateTime(newValue),
      });
    }
  };

  const [tenantsCostAndSLA, setTenantsCostAndSLA] = useState<
    TenantCostAndSLA[]
  >([]);
  const [tenantsCostAndSLACopy, setTenantsCostAndSLACopy] = useState<
    TenantCostAndSLA[]
  >([]);

  const loadTenantsData = () => {
    setLoading(true);
    Promise.all([
      dashboardService.getAzureTenantWiseCost(selectedDateRange),
      dashboardService.getAzureTenantWiseSLA(selectedDateRange),
    ])
      .then(([costs, slas]) => {
        const mergedData = costs.map((cost) => {
          const sla = slas.find((item) => item.TenantName === cost.TenantName);
          return { ...cost, Sla: sla !== undefined ? sla.Sla : 0 };
        });
        setTenantsCostAndSLA(mergedData);
        setTenantsCostAndSLACopy(mergedData);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, tenantsCostAndSLA.length - page * rowsPerPage);

  const CostLoadData = () => {
    loadTenantsData();
  };

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof TenantCostAndSLA,
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSearchFilter(event: React.ChangeEvent<HTMLInputElement>) {
    handleFilter(event.target.value);
  }

  const handleFilter = (searchFilterText: string) => {
    const filteredRows = tenantsCostAndSLACopy.filter(function (costandSla) {
      return (
        costandSla.TenantName.toLowerCase().indexOf(
          searchFilterText.toLowerCase(),
        ) >= 0
      );
    });
    setTenantsCostAndSLA(filteredRows);
  };

  const handleDownloadCSV = () => {
    const csvContent = [
      ['TenantName', 'Cost (NOK kr)', 'Cost (USD $)', 'Sla (%)'],
      ...tenantsCostAndSLA.map((item) => [
        item.TenantName,
        item.Cost,
        item.CostUsd,
        item.Sla ? item.Sla : 'N/A',
      ]),
    ]
      .map((e) => e.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'tenants_cost_and_sla.csv');
    link.click();
  };

  return (
    <div>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <Paper sx={{ marginBottom: 2 }}>
            <CostHeaderToolbar
              onFilterRequest={handleSearchFilter}
              handleFromDateChange={handleFromDateChange}
              handleToDateChange={handleToDateChange}
              CostLoadData={CostLoadData}
              fromDate={dayjs(selectedDateRange.From)}
              toDate={dayjs(selectedDateRange.To)}
              handleDownloadCSV={handleDownloadCSV}
            />
          </Paper>

          <Paper>
            <div className={classes.tableWrapper}>
              <Table className={classes.table}>
                <CostTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(tenantsCostAndSLA, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((tenant) => (
                      <TableRow key={tenant.TenantName}>
                        <TableCell component="th" scope="row">
                          <Typography variant="subtitle2">
                            {tenant.TenantName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {Math.round((tenant.Cost + Number.EPSILON) * 100) /
                            100}
                        </TableCell>
                        <TableCell>
                          {Math.round((tenant.CostUsd + Number.EPSILON) * 100) /
                            100}
                        </TableCell>
                        <TableCell>
                          {tenant.Sla === 0
                            ? 'N/A'
                            : Math.round((tenant.Sla + Number.EPSILON) * 100) /
                              100}
                        </TableCell>
                      </TableRow>
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      colSpan={9}
                      count={tenantsCostAndSLA.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'Rows per page' },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </Paper>
        </>
      )}
    </div>
  );
};

export default CostPanel;
