import React, { ChangeEvent } from 'react';
import {
  Theme,
  Toolbar,
  InputBase,
  FormControlLabel,
  Switch,
  Autocomplete,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { lighten, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { CustomerHeaderToolbarProps } from '../Model/CustomerProps';
import { Link } from 'react-router-dom';

export const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    textfieldlong: {
      width: 600,
    },
    customCell: {
      width: 300,
    },
    textFieldMedium: {
      width: 250,
    },
    resize: {
      fontSize: '0.875rem',
    },
    button: {
      margin: theme.spacing(1),
      width: 200,
    },
    smallbutton: {
      margin: theme.spacing(1),
      width: 100,
    },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    divider: {
      marginTop: 5,
      marginBottom: 10,
    },
    highlight: {
      paddingRight: 10,
      color: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: lighten(theme.palette.common.black, 0.95),
      height: 35,
    },
    spacer: {
      flex: '1 1 100%',
    },
    title: {
      flex: '0 0 auto',
    },
    hideIcons: {
      '& input::-ms-clear, & input::-ms-reveal': {
        display: 'none',
      },
      '& input::-webkit-clear-button, & input::-webkit-search-clear-button, & input::-webkit-search-reveal':
        {
          display: 'none',
        },
      width: 600,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    select: {
      width: '200px',
    },
    maxSelect: {
      width: '500px',
    },
    menuPaper: {
      maxHeight: 400,
    },
    autoComplete: {
      minWidth: 400,
    },
    container: {
      display: 'inline-flex',
    },
    CheckBoxFarmControl: {
      width: 'max-content',
    },
    search: {
      position: 'relative',
      border: `solid 1px ${theme.palette.secondary.dark.toString()}`,
      borderRadius: '5px',
      backgroundColor: alpha(theme.palette.common.black, 0.1),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.05),
        border: `solid 1px ${theme.palette.secondary.light.toString()}`,
      },
      '&:focus': {
        border: `solid 1px primary`,
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
      marginRight: 30,
    },
    mandatoryField: {
      color: theme.palette.error.main,
      display: 'inline',
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
  }),
);
export default function CustomerHeaderToolbar(
  props: Readonly<CustomerHeaderToolbarProps>,
) {
  const {
    onFilterRequest,
    onCollaborationOnlyFilter,
    customerTags,
    onSelectedCustomerTag,
    selectedCustomerTags,
  } = props;
  const [showCollaborationCustomers, setCollaborationOnlyFlag] =
    React.useState(false);
  const [searchFilterText, setSearchFilterText] = React.useState('');
  const classes = useToolbarStyles();
  const createFilterHandler = () => (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilterText(event.target.value);
    onFilterRequest(showCollaborationCustomers, event);
  };
  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollaborationOnlyFlag(event.target.checked);
    onCollaborationOnlyFilter(searchFilterText, event.target.checked);
  };

  return (
    <Toolbar className={classes.root}>
      <div className={classes.spacer} />
      <Autocomplete
        className={classes.autoComplete}
        multiple
        value={selectedCustomerTags}
        options={customerTags}
        onChange={(event, newValue) => {
          onSelectedCustomerTag(newValue);
        }}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <div className={classes.root}>
            <TextField placeholder="Select Tags" {...params} size="small" />
          </div>
        )}
      />
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'Search' }}
          onChange={createFilterHandler()}
        />
      </div>
      <FormControlLabel
        className={`${classes.title} ${classes.highlight}`}
        control={
          <Switch
            checked={showCollaborationCustomers}
            onChange={handleChange()}
            color="primary"
          />
        }
        label="collaboration only"
      />
      <Button
        variant="contained"
        className={classes.button}
        startIcon={<AddIcon />}
        component={Link}
        to={'customer/create'}
      >
        Add
      </Button>
    </Toolbar>
  );
}
