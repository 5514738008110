import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React from 'react';
import CostPanel from './Cost/CostPanel';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Props } from '../DashBoard/Model/HealthModels';
import GlobalHealth from '../DashBoard/Components/GlobalHealth';
import TenantHealthPage from '../DashBoard/Components/TenantHealthPage';
import FavoriteIcon from '@mui/icons-material/Favorite';

const TenantDashboardHome = ({ Product, setDropDown }: Props) => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label="Health"
              value="1"
              icon={<FavoriteIcon htmlColor="#FF0000" />}
              iconPosition="start"
            />
            <Tab
              label="Cost / SLA"
              icon={<MonetizationOnIcon htmlColor="#38761d" />}
              iconPosition="start"
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          {' '}
          <GlobalHealth Product={Product} setDropDown={setDropDown} />
          <TenantHealthPage Product={Product} setDropDown={setDropDown} />
        </TabPanel>
        <TabPanel value="2">
          <CostPanel />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default TenantDashboardHome;
