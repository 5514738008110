import { InputBase, Toolbar, Button, Box } from '@mui/material';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import React, { ChangeEvent } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { CostHeaderToolbarProps } from '../Model/CostModel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function CostHeaderToolbar(
  props: Readonly<CostHeaderToolbarProps>,
) {
  const {
    onFilterRequest,
    handleFromDateChange,
    handleToDateChange,
    CostLoadData,
    handleDownloadCSV,
    fromDate,
    toDate,
  } = props;
  const classes = useToolbarStyles();
  const createFilterHandler = () => (event: ChangeEvent<HTMLInputElement>) => {
    onFilterRequest(event);
  };

  return (
    <Toolbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            p: 1,
            m: 0,
          }}
          component="ul"
        >
          <ListItem>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="From"
                onChange={handleFromDateChange}
                value={fromDate}
              />
            </LocalizationProvider>
          </ListItem>
          <ListItem>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="To"
                onChange={handleToDateChange}
                value={toDate}
              />
            </LocalizationProvider>
          </ListItem>
          <ListItem>
            <Button
              onClick={CostLoadData}
              variant="contained"
              color="primary"
              startIcon={<ManageSearchIcon />}
            >
              Search
            </Button>
          </ListItem>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 1,
            m: 0,
            listStyle: 'none',
          }}
        >
          <ListItem>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'Search' }}
                onChange={createFilterHandler()}
              />
            </div>
          </ListItem>
          <ListItem>
            <Button
              onClick={handleDownloadCSV}
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
            >
              Download CSV
            </Button>
          </ListItem>
        </Box>
      </Box>
    </Toolbar>
  );
}
